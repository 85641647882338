<template>
	<div class="container"><div v-html="detail.introduce"></div></div>
</template>

<script>
export default {
	data() {
		return {
			detail: {}
		};
	},
	async created() {
		let detail = '';
		if (this.$route.path === '/integral/rule') {
			detail = await this.$service.post('point/actInfo', {});
		} else {
			detail = await this.$service.post('point/amountActInfo', {});
		}
		this.detail = detail.data;
	},
	methods: {}
};
</script>

<style scoped>
.container {
	padding: 0.32rem 0.48rem;
	box-sizing: border-box;
}
</style>
